<template>
  <div class="app-install" v-loading.fullscreen="fullscreenLoading">
    <!-- <div class="title" v-if="!notIframe">Onerway Setup Instruction</div>
    <div class="title" v-if="notIframe">Onerway Activate</div> -->
    <div class="title">Onerway Setup Instruction</div>
    <!-- <div class="steps">
      <div class="item"><span class="num">1</span><span class="content">Create Onerway account.</span></div>
      <div class="item"><span class="num">2</span><span class="content">Log into your Onerway account.</span></div>
      <div class="item"><span class="num">3</span><span class="content">Click Acquiring > Application > My application to get Store Name & Store ID of that application.</span></div>
    </div> -->
    <div class="form-wrapper">
      <el-form label-position="top" :model="form" :rules="rules" ref="formRef" @submit.native.prevent>
        <!-- <el-form-item v-if="!notIframe" label="Merchant ID" prop="v3_app_id" ref="effectiveDateRef">
          <el-input
            autocomplete="off"
            v-model="form.v3_app_id"/>
        </el-form-item> -->
        <el-form-item label="Merchant ID" prop="v3_app_id" ref="effectiveDateRef">
          <el-input
            autocomplete="off"
            v-model="form.v3_app_id"/>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button type="primary" @click="install">Activate</el-button>
        <!-- <el-button type="primary" v-if="!notIframe" @click="install">Check</el-button>
        <el-button type="primary" v-if="notIframe" @click="activate">Activate</el-button> -->
      </div>
    </div>
    <div class="tips">* If you have any problem, please contact with our Support Team: merchant@pacypay.com.</div>
  </div>
</template>
<script>
import Client, { Oauth, shared } from '@shoplinedev/appbridge';

import { installPaymentAppSplicing, installPaymentAppActive } from '@/api/plugin';

export default {
  data() {
    return {
      notIframe: false,
      fullscreenLoading: false,
      form: {
        v3_app_id: '',
      },
      rules: {
        v3_app_id: [{ required: true, message: 'Please enter a valid merchant id', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.$store.dispatch('app/setBrandInfo', {});
    this.notIframe = window.self === window.top;
  },
  methods: {
    activate() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          const self = this;
          this.fullscreenLoading = true;
          const { appkey, appName } = this.$route.query;
          const data = {
            ...this.$route.query,
            merchantNo: this.form.v3_app_id,
          };
          // 通过应用配置appName
          installPaymentAppActive('shopline', appName, data).then((res) => {
            if (res.respCode === '20000') {
              this.$router.push({
                path: '/v1/plugin/appInstall/shopline/success',
                query: this.$route.query,
              });
            } else {
              this.$router.push({
                path: '/v1/plugin/appInstall/shopline/failed',
                query: { m: res.respMsg || '' },
              });
            }
            this.fullscreenLoading = false;
          }).catch(() => {
            this.$router.push({
              path: '/v1/plugin/appInstall/shopline/failed',
            });
            this.fullscreenLoading = false;
          });
        }
      });
    },
    install() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          const self = this;
          this.fullscreenLoading = true;
          const { appkey, appName } = this.$route.query;
          const data = {
            ...this.$route.query,
            merchantNo: this.form.v3_app_id,
          };
          // 通过应用配置appName
          installPaymentAppSplicing('shopline', appName, data).then((res) => {
            if (res.respCode === '20000') {
              if (res.data && res.data.redictUri) {
                const _redictUri = res.data.redictUri;
                const redictUriSplit = _redictUri.split('?')[1];
                const scope = new URLSearchParams(redictUriSplit).get('scope');
                const redirectUri = new URLSearchParams(redictUriSplit).get('redirectUri');
                // self.activatedSuccess(redirectUri, appkey, scope);
                window.location.href = res.data.redictUri;
              }
              return;
            }
            this.$router.push({
              path: '/v1/plugin/appInstall/shopline/failed',
              query: { m: res.respMsg || '' },
            });
            this.fullscreenLoading = false;
          }).catch(() => {
            this.$router.push({
              path: '/v1/plugin/appInstall/shopline/failed',
            });
            this.fullscreenLoading = false;
          });
        }
      });
    },
    // 激活成功，唤起授权
    activatedSuccess(redirect, appKey, scope) {
      if (redirect) {
        const app = Client.createApp({
          appKey,
          host: shared.getHost(),
        });

        Oauth.create(app).invoke({
          scope,
          appKey,
          redirectUri: redirect,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.app-install {
  width: 760px;
  max-width: 100%;
  padding: 40px 0;
  margin: 0 auto;
  text-align: left;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #27272B;
    line-height: 20px;
    text-align: center;
  }
  .steps {
    padding-top: 20px;
    .item {
      padding: 6px 10px;
      margin-top: 10px;
      background-color: #F2F2F2;
      border-radius: 15px;
      position: relative;
      .num {
        display: inline-block;
        text-align: center;
        width: 20px;
        height: 20px;
        background-color: #fff;
        font-size: 14px;
        font-weight: normal;
        color: #5A6D7C;
        line-height: 20px;
        margin-right: 8px;
        border-radius: 50%;
        position: absolute;
      }
      .content {
        display: inline-block;
        padding-left: 27px;
        line-height: 20px;
        vertical-align: top;
      }
    }
  }
  .form-wrapper {
    margin-top: 30px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #27272B;
      line-height: 20px;
    }
    ::v-deep .el-form {
      .el-form-item {
        margin-bottom: 30px;
      }
      .el-form-item__label {
        font-size: 16px;
        font-weight: 500;
        color: #27272B;
        line-height: 20px;
        padding-bottom: 9px;
        &::before {
          display: none;
        }
      }
    }
    .btns {
      padding-top: 20px;
      button {
        width: 100%;
      }
    }
  }
  .tips {
    font-size: 14px;
    font-family: Avenir-Book, Avenir;
    font-weight: normal;
    color: #27272B;
    line-height: 20px;
    margin-top: 20px;
  }
}
</style>
